<template>
	<div>
		<el-button @click="getTripData" size="small">刷新</el-button>
		<el-table :data="tableData" v-loading="tableLoading" :header-cell-style="{background:'#E4E7ED',color: '#606266'}"
			border stripe size="small" style="width: 100%;margin-top: 10px;" max-height="500px">
			<el-table-column label="#" type="index" width="60"></el-table-column>
			<el-table-column label="申请人" prop="applicant"></el-table-column>
			<el-table-column label="申请时间" prop="apply_time"></el-table-column>
			<el-table-column label="派车时间" prop="dispatch_time"></el-table-column>
			<el-table-column label="返回时间" prop="return_time"></el-table-column>
			<el-table-column label="目的地" prop="target"></el-table-column>
			<el-table-column label="车辆" prop="car.name"></el-table-column>
		</el-table>
		<div class="margin-t-10 flex">
			<el-pagination background layout="prev, pager, next, total, jumper" :total="total"
				:current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getTripData">
			</el-pagination>
			<el-button size="small">确定</el-button>
		</div>
	</div>
</template>

<script>
	import {
		getDriverTripDataAPI,
	} from '@/api/dispatch-vehicle.js'

	export default {
		name: 'Trip',
		data() {
			return {
				queryForm: {
					page: 1,
				},
				tableData: [],
				total: 0,
				pageSize: 0,
				tableLoading: false,
			}
		},
		created() {
			this.getTripData()
		},
		methods: {
			// 查询按钮
			handleSearch() {
				this.queryForm.page = 1
				this.getTripData()
			},
			// 获取出行安排数据
			getTripData() {
				this.tableLoading = true

				getDriverTripDataAPI(this.queryForm).then(res => {
					this.tableData = res.data
					this.total = res.total
					this.pageSize = res.per_page
					this.tableLoading = false
				})
			}
		}
	}
</script>

<style scoped>
</style>
